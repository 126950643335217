<template>
  <div>
    <div class="bjffff"></div>
    <div class="contentBody">
      <div class="contentBox">
        <div class="oderHeadBox">
          <div class="imgBox">
            <div class="img">
              <img src="../../assets/img/logo-p.png" alt="" />
            </div>
            <div class="name">收银台</div>
          </div>
          <div class="textBox">
            <div class="txt">
              {{ user.nickname ? user.nickname : user.name }}
            </div>
            <div class="shu"></div>
            <div class="txt">我的订单</div>
          </div>
        </div>
        <div class="oderHeadBox">
          <div class="name">
            订单提交成功，请尽快付款!订单号:{{ info.ordernum }}
          </div>
          <div class="moneyBox">
            <div class="txt">应付金额</div>
            <div class="num">{{ info.price }}</div>
            <div class="txt">元</div>
          </div>
        </div>
        <div class="content">
          <div class="typeList">
            <div class="name">微信支付</div>
          </div>
          <div class="contBox">
            <div class="head">
              <span v-if="num == 0">二维码已过期,请刷新页面</span>
              <span v-if="num != 0"
                >二距离二维码过期还剩{{ num }}秒,
                过期后请刷新页面重新获取二维码</span
              >
            </div>
            <div class="img">
              <!-- <img
                src="https://img0.baidu.com/it/u=46505623,3598573663&fm=253&app=120&size=w931&n=0&f=JPEG&fmt=auto?sec=1722186000&t=ce04294e5f6049ce9a2d1d76955baee6"
                alt=""
                srcset=""
              /> -->
              <div class="imgMh" v-if="num == 0"></div>
              <div class="imgMhBox dis-cent" v-if="num == 0" @click="sxClick">
                <div class="box1">
                  <img src="../../assets/img/xuanzhuan.png" alt="" srcset="" />
                </div>
              </div>
              <canvas ref="qrcodeCanvas"></canvas>
            </div>
            <div class="box">
              <div class="iconfont icon-saomiao"></div>
              <div class="texts">
                <div class="name">请使用微信扫一扫</div>
                <div class="name">扫描二维码支付</div>
              </div>
            </div>
          </div>
          <div class="tu">
            <img src="../../assets/img/fkImg.png" alt="" />
          </div>
        </div>
        <div class="btnTxt">浙江川田智能科技有限公司</div>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketInit from "@/assets/js/wxb";
import QRCode from "qrcode";
export default {
  name: "",
  data() {
    return {
      num: 60,
      user: {},
      info: {},
    };
  },
  created() {
    let member = window.localStorage.getItem("member");
    this.user = JSON.parse(member);
    this.id = this.$route.query.id;
    this.time = setInterval(() => {
      this.num--;
      if (this.num == 0) {
        clearInterval(this.time);
        window.ws1.close();
      }
    }, 1000);
    this.duankai = window.localStorage.setItem("duankai", "2");
    this.WebSocketGet();
    this.get();
  },
  destroyed() {
    this.duankai = window.localStorage.setItem("duankai", "1");
    window.ws1.close();
  },
  methods: {
    sxClick() {
      location.reload();
    },
    generateQRCode(url) {
      QRCode.toCanvas(
        this.$refs.qrcodeCanvas,
        url,
        {
          width: 500,
          height: 500,
        },
        (error) => {
          if (error) console.error(error);
        }
      );
    },
    payGet() {
      this.$post("/pay/pay", {
        paytype: 2,
        id: this.id,
      }).then((res) => {
        console.log(res);
        this.generateQRCode(res.data.data.code_url);
      });
    },
    get() {
      this.$get("/web/order_get", {
        id: this.id,
      }).then((res) => {
        this.info = res.data;
        if (res.data.status == 2) {
          this.$message.success("该订单已经完成支付");
          let url = "/chuantian/#/orderCont?id=" + this.id;
          const newWindow = window.open(url, "_blank");
          if (newWindow) {
            // 尝试关闭当前窗口
            window.close();
            // 如果当前窗口未关闭，则在新窗口打开目标页面并关闭当前页面
            if (!window.closed) {
              newWindow.focus();
            }
          } else {
            // 如果打开新窗口失败（如被浏览器拦截），则在当前窗口跳转
            window.location.href = url;
          }
        } else {
          this.payGet();
        }
      });
    },
    WebSocketGet() {
      WebSocketInit("/ws/order?order_id=" + this.id);
      const getsocketData = (e) => {
        // 创建接收消息函数
        const data = e && e.detail.data;
        let url = this.$imgUrl + "/chuantian/#/orderCont?id=" + this.id;
        console.log(data);
        if (data.status == 2) {
          this.$message.success("该订单已经完成支付");
          const newWindow = window.open(url, "_blank");
          if (newWindow) {
            // 尝试关闭当前窗口
            window.close();
            // 如果当前窗口未关闭，则在新窗口打开目标页面并关闭当前页面
            if (!window.closed) {
              newWindow.focus();
            }
          } else {
            // 如果打开新窗口失败（如被浏览器拦截），则在当前窗口跳转
            window.location.href = url;
          }
        }
      };
      // // 注册监听事件
      window.addEventListener("onmessageWS", getsocketData);
    },
    getsocketResult(data) {
      console.log(data, "后端返回的信息");
    },
    // 调用方法传入参数,发送socket信息
    websocketSend(data) {
      this.$socketApi.sendSock(data);
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.contentBody {
  width: 100%;
  .contentBox {
    width: 1440px;
    margin: auto;
    box-sizing: border-box;
    .oderHeadBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      padding-bottom: 0px;
      .imgBox {
        display: flex;
        align-items: center;
        .img {
          height: 50px;
        }
        .name {
          font-weight: 600;
          margin-top: 12px;
          margin-left: 5px;
          font-size: 15px;
        }
      }
      .textBox {
        display: flex;
        align-items: center;
        font-size: 12px;
        .shu {
          height: 15px;
          width: 1px;
          margin: 0 15px;
          background-color: #c9c9c9;
        }
        .txt {
          cursor: pointer;
          color: #666;
        }
      }
      .moneyBox {
        display: flex;
        align-items: center;
        .txt {
          font-size: 12px;
          color: #555;
        }
        .num {
          font-weight: 600;
          margin: 0 2px;
          color: #db0808;
          font-size: 18px;
        }
      }
    }
    .content {
      border-radius: 5px;
      box-shadow: 0 0 14px 7px #ebebeb;
      display: flex;
      margin-top: 50px;
      padding-bottom: 30px;
      overflow: hidden;
      .typeList {
        width: 200px;
        .name {
          padding-left: 20px;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          font-weight: 500;
        }
      }
      .contBox {
        width: 600px;
        .head {
          height: 50px;
          line-height: 50px;
        }
        .img {
          width: 500px;
          height: 500px;
          border: 1px solid #ebebeb;
          position: relative;
          // padding: 20px;
          .imgMh {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            // filter: blur(5px);
            backdrop-filter: blur(10px);
          }
          .imgMhBox {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            .box1 {
              width: 60px;
              cursor: pointer;
            }
            // filter: blur(5px);
          }
        }
        .box {
          background-color: #d83434;
          border-radius: 5px;
          width: 500px;
          margin-top: 20px;
          height: 80px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconfont {
            font-size: 65px;
          }
          .texts {
            margin-left: 20px;
            .name {
              font-size: 16px;
            }
          }
        }
      }
      .tu {
        width: calc(100% - 600px - 200px);
        img {
          width: 500px;
          margin-top: 10px;
        }
      }
    }
    .btnTxt {
      margin-top: 40px;
      border-top: 1px solid #d6d6d6;
      padding-top: 20px;
      text-align: center;
    }
  }
}
</style>
